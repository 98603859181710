var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper"},[_c('div',{staticClass:"form-wrapper shadow-lvl-1"},[_c('form',{staticClass:"form login-form",on:{"submit":function($event){$event.preventDefault();return _vm.send($event)}}},[_vm._m(0),_c('p',{staticClass:"form__name"},[_vm._v("Вход в личный кабинет кандидата")]),_c('div',{staticClass:"form__fields"},[_c('div',{staticClass:"input-wrapper form__input"},[_c('text-input',{attrs:{"id":"login","placeholder":"Логин","type":"login","tabindex":"1","invalid":_vm.$v.login.$error || _vm.loginError.status},on:{"input":function () {
                _vm.$v.login.$reset()
                _vm.loginError.status = false
                _vm.loginError.message = null
              }},model:{value:(_vm.$v.login.$model),callback:function ($$v) {_vm.$set(_vm.$v.login, "$model", $$v)},expression:"$v.login.$model"}})],1),_c('div',{staticClass:"input-wrapper form__input"},[_c('button',{staticClass:"form__input-btn",attrs:{"type":"button","tabindex":"3"},on:{"click":function($event){_vm.passInputType =
                _vm.passInputType == 'password' ? 'text' : 'password'}}},[_c('Icon',{attrs:{"iconName":_vm.passInputType == 'password'
                  ? 'password-eye-close'
                  : 'password-eye-open'}})],1),_c('text-input',{attrs:{"id":"password","placeholder":"Пароль","type":_vm.passInputType,"tabindex":"2","invalid":_vm.$v.password.$error || _vm.loginError.status},on:{"input":function () {
                _vm.$v.password.$reset()
                _vm.loginError.status = false
                _vm.loginError.message = null
              }},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}})],1)]),(_vm.$v.agreement.$error || _vm.$v.$error || _vm.loginError.status)?_c('p',{staticClass:"error-message fz-14 red"},[_vm._v(" "+_vm._s(_vm.$v.$error ? _vm.errorMessage : _vm.loginError.message)+" ")]):_vm._e(),_c('div',{staticClass:"checkbox-wrapper"},[_c('custom-checkbox',{attrs:{"id":"agreement-checkbox","invalid":_vm.$v.agreement.$error},on:{"change":function($event){return _vm.$v.agreement.$touch()}},model:{value:(_vm.$v.agreement.$model),callback:function ($$v) {_vm.$set(_vm.$v.agreement, "$model", $$v)},expression:"$v.agreement.$model"}},[_vm._v("Даю согласие на "),_c('a',{staticClass:"download-link",attrs:{"target":"_blank","href":"/proxy.php?className=login&action=get_pdf&file_request=true"}},[_c('span',{staticClass:"fw-500 blue"},[_vm._v("обработку")]),_c('br'),_c('span',{staticClass:"fw-500 blue"},[_vm._v("персональных данных")])])])],1),_c('div',{staticClass:"form__buttons"},[_c('custom-button',{staticClass:"form__button darkBlue"},[_vm._v("Войти")]),_c('custom-button',{staticClass:"form__button blue",attrs:{"disabled":_vm.linkDisabled,"type":"button"},on:{"click":_vm.generatePass}},[_vm._v("Отправить пароль на E-mail")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"companylogo"},[_c('img',{attrs:{"src":"/proxy.php?className=login&action=get_logo&file_request=true","alt":"НОТА","height":"24"}})])}]

export { render, staticRenderFns }