<template>
  <div id="app">
    <div v-if="$store.state.loading" class="loading">
      <img
        class="loading__rotator"
        src="@/assets/img/preloader.png"
        alt="loader"
      />
    </div>
    <div class="container">
      <!-- <header class="header">
        <img
          src="/proxy.php?className=login&action=get_logo&file_request=true"
          alt="logo"
          class="header__logo"
        />
      </header> -->
      <div class="container-min">
        <router-view :key="$route.path"></router-view>
        <!-- <Files /> -->
      </div>
    </div>
  </div>
</template>
<script>
// import testData from './lkk_files.json'
// import Files from '@/views/questionnaire/Files'
import '@/assets/css/message.css'
export default {
  // components: { Files },
  // created() {
  //   this.$store.state.page.questions = testData;
  // },
}
</script>
<style lang="scss">
body {
  font-family: Roboto, 'Roboto', sans-serif;
  background: #c9d8e5;
}

* {
  font-family: 'Roboto', Roboto, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#app {
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  a {
    &:hover,
    &:hover * {
      color: $blue-120;
    }
  }

  .blue {
    color: $blue-120;
  }

  .blue-130 {
    color: $blue-130;
  }

  .primary {
    color: $primary-text;
  }

  .secondary {
    color: $secondary-text;
  }

  .disabled-reg {
    color: $disabled-reg-text;
  }

  .disabled-med {
    color: $disabled-med-text;
  }

  .red {
    color: $red;
  }

  .fw-500 {
    font-family: 'Roboto Medium';
    font-weight: 500;
  }

  .bold {
    font-weight: bold;
  }

  .shadow-lvl-1 {
    box-shadow: 0px 1px 4px rgba(33, 39, 44, 0.09);
  }

  .shadow-lvl-2 {
    box-shadow: 0px 4px 15px rgba(33, 39, 44, 0.1);
  }

  .shadow-lvl-3 {
    box-shadow: 0px 0px 40px rgba(33, 39, 44, 0.1);
  }

  .shadow-lvl-4 {
    box-shadow: 0px 0px 55px rgba(33, 39, 44, 0.1);
  }

  .brs-4 {
    border-radius: 4px;
  }

  .brs-8 {
    border-radius: 8px;
  }

  .fz-14 {
    font-size: 14px;
    line-height: 20px;
  }

  .fz-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .link {
    color: $blue-120;

    &:hover {
      color: $blue-130;
    }

    &-space {
      margin-bottom: 24px;
    }

    &-space-top {
      color: $black-30;
      margin-top: 33px;
      margin-bottom: 24px;
    }

    &-space-bottom {
      color: $black-30;
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(90deg);
  }
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  font-size: 48px;
  z-index: 100;

  &__rotator {
    width: 52px;
    animation: 0.6s ease-in-out 0s infinite rotate;
  }
}

// notifications
.el-message {
  min-height: 48px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 12px 12px 16px;

  &__closeBtn {
    font-weight: 800;
    color: $black-200;
    right: 30px;
  }

  &__content {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  &.is-closable &__content {
    padding-right: 48px;
    padding-left: 12px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    position: relative;
    margin: 0;
  }

  &__icon:before,
  &__closeBtn:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 24px;
    height: 24px;
    outline: none;
    background-color: transparent;
  }

  .el-icon-error {
    font-size: 20px;
    color: $red-150;

    &:before {
      background-image: url('./assets/img/error.svg');
    }
  }

  .el-icon-warning {
    font-size: 20px;
    color: $yellow-150;

    &:before {
      background-image: url('./assets/img/warning.svg');
    }
  }

  .el-icon-success {
    color: $green-150;
    font-size: 20px;

    &:before {
      background-image: url('./assets/img/success.svg');
    }
  }

  .el-icon-close:before {
    background-image: url('./assets/img/close.svg');
  }

  &--warning {
    background: $yellow-30;

    .el-message__content {
      color: $yellow-150;
    }
  }

  &--success {
    background: $green-30;

    .el-message__content {
      color: $green-150;
    }
  }

  &--error {
    background: $red-30;

    .el-message__content {
      color: $red-150;
    }
  }

  &--info {
    background-color: $black-200;
    border: none;

    .el-message__content {
      color: $white;
      padding-left: 0;
    }

    .el-icon-close:before {
      background-image: url('./assets/img/close-white.svg');
    }

    .el-message__icon {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.container {
  // width: calc(100% - 32px);
  max-width: 1200px;
  margin: 0 auto;
  // padding: 50px 0 120px 0;
}

.container-min {
  width: 100%;
  // max-width: 800px;
}

// .header {
//   width: 100%;
//   margin: 32px;

//   &__logo {
//     max-width: 320px;
//     max-height: 70px;
//   }
// }
</style>
