import Vue from 'vue'
import App from './App.vue'

import Vuelidate from 'vuelidate'
import { Message } from 'element-ui'

import { store } from './store/index'
import router from './router'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

Vue.use(Vuelidate)
locale.use(lang)
Vue.prototype.$message = Message
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
