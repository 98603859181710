<template>
  <div class="auth-wrapper">
    <div class="form-wrapper shadow-lvl-1">
      <form @submit.prevent="send" class="form login-form">
        <div class="companylogo">
          <img
            src="/proxy.php?className=login&action=get_logo&file_request=true"
            alt="НОТА"
            height="24"
          />
        </div>
        <p class="form__name">Вход в личный кабинет кандидата</p>
        <div class="form__fields">
          <div class="input-wrapper form__input">
            <text-input
              id="login"
              v-model="$v.login.$model"
              placeholder="Логин"
              type="login"
              tabindex="1"
              :invalid="$v.login.$error || loginError.status"
              @input="
                () => {
                  $v.login.$reset()
                  loginError.status = false
                  loginError.message = null
                }
              "
            />
          </div>
          <div class="input-wrapper form__input">
            <button
              class="form__input-btn"
              type="button"
              tabindex="3"
              @click="
                passInputType =
                  passInputType == 'password' ? 'text' : 'password'
              "
            >
              <Icon
                :iconName="
                  passInputType == 'password'
                    ? 'password-eye-close'
                    : 'password-eye-open'
                "
              />
            </button>
            <text-input
              v-model="$v.password.$model"
              id="password"
              placeholder="Пароль"
              :type="passInputType"
              tabindex="2"
              :invalid="$v.password.$error || loginError.status"
              @input="
                () => {
                  $v.password.$reset()
                  loginError.status = false
                  loginError.message = null
                }
              "
            />
          </div>
        </div>
        <p
          v-if="$v.agreement.$error || $v.$error || loginError.status"
          class="error-message fz-14 red"
        >
          {{ $v.$error ? errorMessage : loginError.message }}
        </p>
        <div class="checkbox-wrapper">
          <custom-checkbox
            id="agreement-checkbox"
            v-model="$v.agreement.$model"
            :invalid="$v.agreement.$error"
            @change="$v.agreement.$touch()"
            >Даю согласие на
            <a
              class="download-link"
              target="_blank"
              href="/proxy.php?className=login&action=get_pdf&file_request=true"
            >
              <span class="fw-500 blue">обработку</span><br />
              <span class="fw-500 blue">персональных данных</span></a
            >
          </custom-checkbox>
        </div>
        <div class="form__buttons">
          <custom-button class="form__button darkBlue">Войти</custom-button>
          <custom-button
            :disabled="linkDisabled"
            type="button"
            class="form__button blue"
            @click="generatePass"
            >Отправить пароль на E-mail</custom-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import TextInput from '@/components/elements/TextInput'
import Icon from '@/components/elements/Icon'
import CustomCheckbox from '@/components/elements/Checkbox'
import CustomButton from '@/components/elements/Button'
import { generatePassword, login } from '@/controllers/auth'

export default {
  components: {
    TextInput,
    Icon,
    CustomCheckbox,
    CustomButton,
  },
  validations: {
    login: { required },
    password: { required },
    agreement: { sameAs: sameAs(() => true) },
  },
  data() {
    return {
      linkDisabled: false,
      loginError: {
        status: false,
        message: null,
      },
      login: null,
      password: null,
      agreement: true,
      passInputType: 'password',
    }
  },
  mounted() {
    this.$store.commit('loading', false)
  },
  methods: {
    async generatePass() {
      this.loginError.status = false

      if (!this.$v.login.$model) {
        this.$store.commit('showNotification', {
          message:
            'Для отправки разового пароля необходимо заполнить поле ввода логина',
          type: 'error',
        })

        return null
      } else {
        this.$store.commit('showNotification', {
          message: 'Отправляем пароль вам на почту...',
          type: 'info',
        })

        try {
          await generatePassword(this.login)
          this.linkDisabled = false

          this.$store.commit('showNotification', {
            message: 'Пароль отправлен вам на почту',
          })
        } catch (e) {
          this.$store.commit('showNotification', {
            message: 'Проверьте введенные данные и попробуйте еще раз',
            type: 'error',
          })

          this.linkDisabled = false
        }
      }
    },

    async send() {
      this.$v.$touch()

      if (!this.$v.$error) {
        try {
          this.$store.commit('loading', true)

          await login({
            login: this.login,
            password: this.password,
          })

          this.$router.push({
            name: 'questionnaire',
          })
        } catch (error) {
          switch (error.response.status) {
            case 500:
              this.$store.commit('showNotification', {
                message: 'Произошла ошибка при отправке, попробуйте еще раз',
                type: 'error',
              })
              break
            default:
              this.loginError.status = true
              this.loginError.message = 'Ошибка в логине или пароле'
              this.$store.commit('loading', false)
              break
          }
        }
      }
    },
  },
  computed: {
    errorMessage() {
      if (this.$v.login.$invalid || this.$v.password.$invalid)
        return 'Ошибка в логине или пароле, отправить новый пароль'
      if (!this.agreement) {
        return 'Для продолжения необходимо согласие на обработку'
      }
      return ''
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/form';

.auth-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 !important;
}

.checkbox-wrapper {
  margin-top: 16px;
}

.error-message {
  margin-top: 4px;
  margin-bottom: 12px;
}

.download-link {
  text-decoration: none;

  span:last-child {
    margin-left: 30px;
  }
}

.form-wrapper {
  margin: auto !important;
  background: $white;
  width: 364px;
  padding: 32px 32px 48px;
  border-radius: 6px;

  .companylogo {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 32px;

    img {
      max-width: 240px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  margin: 0;

  &__group {
    width: 100%;
    margin-top: 12px;
    position: relative;

    &_error ::v-deep input {
      border: 1px solid $red-700;
    }
  }

  &__fields + .checkbox-wrapper {
    margin-top: 28px;
  }

  &__input {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &-btn {
      position: absolute;
      border: none;
      background: transparent;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 12px;
      z-index: 1;
      cursor: pointer;
    }
  }

  &__button {
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    padding: 8px 0;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__name {
    font-size: 15px;
    line-height: 24px;
    color: $black-100;
    margin-top: 40px;
  }

  &__buttons {
    width: 100%;
    margin-top: 28px;
  }
}
</style>
