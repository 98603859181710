<template>
  <svg
    v-if="iconName == 'remove'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.44721 3.10557L8 4H5C4.44772 4 4 4.44772 4 5V6H20V5C20 4.44772 19.5523 4 19 4H16L15.5528 3.10557C15.5348 3.06967 15.5159 3.03447 15.496 3C15.1404 2.38385 14.4813 2 13.7639 2H10.2361C9.51866 2 8.85957 2.38385 8.504 3C8.48411 3.03447 8.46516 3.06967 8.44721 3.10557Z"
      :fill="disabled ? disabledColor : iconColor || '#21272C'"
    />
    <path
      d="M14.2929 18.7071L12 16.4142L9.70711 18.7071L8.29289 17.2929L10.5858 15L8.29289 12.7071L9.70711 11.2929L12 13.5858L14.2929 11.2929L15.7071 12.7071L13.4142 15L15.7071 17.2929L14.2929 18.7071Z"
      :fill="disabled ? disabledColor : iconColor || '#21272C'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 20V8H19V20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20ZM7 20V10H17V20H7Z"
      :fill="disabled ? disabledColor : iconColor || '#21272C'"
    />
  </svg>
  <svg
    v-else-if="iconName == 'close'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5859 12L5.29297 6.70712L6.70718 5.29291L12.0001 10.5858L17.293 5.29291L18.7072 6.70712L13.4143 12L18.7072 17.2929L17.293 18.7071L12.0001 13.4142L6.70718 18.7071L5.29297 17.2929L10.5859 12Z"
      :fill="iconColor || '#21272C'"
    />
  </svg>
  <svg
    v-else-if="iconName == 'accept'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6783 9.7348C18.0841 9.3602 18.1094 8.72754 17.7348 8.32172C17.3602 7.9159 16.7275 7.89059 16.3217 8.2652L10.5277 13.6135L8.70711 11.7929C8.31658 11.4024 7.68342 11.4024 7.29289 11.7929C6.90237 12.1834 6.90237 12.8166 7.29289 13.2071L10.4723 16.3865L17.6783 9.7348Z"
      fill="#3DB449"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
      :fill="iconColor || '#3DB449'"
    />
  </svg>
  <svg
    v-else-if="iconName == 'decline'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM13.4142 12L16.7071 8.70711L15.2929 7.29289L12 10.5858L8.70711 7.29289L7.29289 8.70711L10.5858 12L7.29289 15.2929L8.70711 16.7071L12 13.4142L15.2929 16.7071L16.7071 15.2929L13.4142 12Z"
      :fill="iconColor || '#EE1C1C'"
    />
  </svg>
  <svg
    v-else-if="iconName == 'preload'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V10.5H18L18 9H13V4L6 4L6 20H10V22H6C4.89543 22 4 21.1046 4 20L4 4C4 2.89543 4.89543 2 6 2L14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289ZM15 5.41421V7H16.5858L15 5.41421Z"
      :fill="iconColor || '#0075DB'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0333 13C13.3581 13 12 14.3581 12 16.0333C12 17.7086 13.3581 19.0667 15.0333 19.0667C16.7086 19.0667 18.0667 17.7086 18.0667 16.0333C18.0667 14.3581 16.7086 13 15.0333 13ZM10 16.0333C10 13.2535 12.2535 11 15.0333 11C17.8132 11 20.0667 13.2535 20.0667 16.0333C20.0667 17.0616 19.7583 18.0179 19.229 18.8148L22.7071 22.2929L21.2929 23.7071L17.8148 20.229C17.0179 20.7583 16.0616 21.0667 15.0333 21.0667C12.2535 21.0667 10 18.8132 10 16.0333Z"
      :fill="iconColor || '#0075DB'"
    />
  </svg>
  <svg
    v-else-if="iconName == 'success'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      :fill="iconColor || '#008026'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.7348 8.32172C18.1094 8.72754 18.0841 9.3602 17.6783 9.7348L10.4723 16.3865L7.29289 13.2071C6.90237 12.8166 6.90237 12.1834 7.29289 11.7929C7.68342 11.4024 8.31658 11.4024 8.70711 11.7929L10.5277 13.6135L16.3217 8.2652C16.7275 7.89059 17.3602 7.9159 17.7348 8.32172Z"
      fill="white"
    />
  </svg>
  <svg
    v-else-if="iconName == 'ellipsis'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="12" r="1" fill="white" />
    <circle cx="12" cy="12" r="1" fill="white" />
    <circle cx="16" cy="12" r="1" fill="white" />
  </svg>
  <svg
    v-else-if="iconName == 'password-eye-close'"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.22907 4.23633L19.7283 19.7356L19.7307 19.7332L19.7315 19.7339L18.3172 21.1482L15.6455 18.4765C14.4986 18.8159 13.2731 18.9995 12 18.9995C6.92156 18.9995 2.60118 16.0777 1 11.9995C1.7114 10.1876 2.95956 8.60395 4.57249 7.4034L2.81725 5.64815L4.22907 4.23633ZM8.3462 5.52503L10.002 7.18081C10.6439 7.06226 11.3118 6.99952 12 6.99952C16.0017 6.99952 19.3196 9.12113 20.8068 11.9995C20.1715 13.229 19.2023 14.3204 17.9885 15.1673L19.4214 16.6002C21.0373 15.3991 22.2877 13.8137 23 11.9995C21.3988 7.92135 17.0784 4.99952 12 4.99952C10.7238 4.99952 9.49552 5.18403 8.3462 5.52503ZM15.8629 13.0417C15.9523 12.7094 16 12.36 16 11.9995C16 9.79038 14.2091 7.99952 12 7.99952C11.6395 7.99952 11.2901 8.04722 10.9578 8.13664L13.3055 10.4843C13.3805 10.549 13.4506 10.6191 13.5152 10.694L15.8629 13.0417ZM13.989 16.8199L13.0337 15.8647C12.704 15.9526 12.3575 15.9995 12 15.9995C9.79086 15.9995 8 14.2087 8 11.9995C8 11.642 8.04689 11.2955 8.13486 10.9658L6.00522 8.83613C4.79433 9.68246 3.82736 10.7722 3.19322 11.9995C4.68044 14.8779 7.99829 16.9995 12 16.9995C12.6849 16.9995 13.3499 16.9374 13.989 16.8199Z"
      fill="#CDD4DA"
    />
  </svg>
  <svg
    v-else-if="iconName == 'password-eye-open'"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="3" stroke="#CDD4DA" stroke-width="2" />
    <path
      d="M12 18C7.45203 18 3.63257 15.4737 2.08396 12C3.63257 8.52634 7.45203 6 12 6C16.548 6 20.3674 8.52634 21.916 12C20.3674 15.4737 16.548 18 12 18Z"
      stroke="#CDD4DA"
      stroke-width="2"
    />
  </svg>
  <svg
    v-else-if="iconName == 'upload'"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 2H16V13H19L12 18.25L5 13H8V2ZM10 14.25L12 15.75L14 14.25V4H10V14.25ZM21 20V22H3V20H21Z"
      :fill="iconColor || 'black'"
    />
  </svg>
</template>
<script>
export default {
  props: {
    iconName: {
      default: '',
    },
    iconColor: {
      default: '',
    },
    disabledColor: {
      default: '#cdd4da',
    },
    disabled: Boolean,
  },
}
</script>
