import { store } from '@/store'

export const errorHandler = (error, callback, msg) => {
  switch (error.response?.status) {
    case 401:
      callback({ name: 'Main' })
      store.commit('showNotification', {
        message: 'Ваша сессия истекла, авторизируйтесь повторно',
        type: 'warning',
        duration: 5000,
      })
      store.commit('loading', false)
      break
    default:
      store.commit('showNotification', {
        message: msg || 'Произошла ошибка, попробуйте еще раз',
        type: 'error',
      })
      break
  }
}
