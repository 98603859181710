<template>
  <button
    :class="[
      'button',
      classes,
      disabled && 'disabled',
      onlyIcon && 'only-icon',
    ]"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    classes: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onlyIcon: false,
    }
  },
  mounted() {
    this.onlyIcon =
      this.$slots.default.length == '1' &&
      this.$slots.default[0].elm?.tagName == 'svg'
  },
}
</script>

<style lang="scss" scoped>
.button {
  font-family: 'Roboto Medium';
  position: relative;
  border-radius: 4px;
  border: none;
  outline: none;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
  border: none;
  // width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  & ::v-deep svg {
    display: inline-block;
    vertical-align: middle;
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  & ::v-deep a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
  }

  & ::v-deep [type='file'] {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    // height: 100%;
    cursor: pointer;
  }

  &.only-icon {
    padding: 0;
  }

  &.big {
    height: 40px;
    padding: 0 16px;
  }

  &.medium {
    height: 32px;
    padding: 0 12px;
  }

  &.small {
    height: 24px;
    padding: 0 8px;
  }

  &:not(:disabled) * {
    cursor: pointer;
  }
}

.blue {
  background-color: $blue-10;
  border-color: transparent;
  color: $blue-120;

  &:hover,
  &:focus {
    background-color: $blue-20;
    color: $blue-130;
  }

  &:disabled {
    background-color: $black-10;
    color: $black-60;
  }
}

.darkBlue {
  background-color: $blue-120;
  color: $white;

  &:hover,
  &:focus {
    background-color: $blue-130;
  }

  &:disabled {
    background-color: $black-50;
    color: $white;
  }
}

.transparent {
  background-color: transparent;
  border-color: transparent;
  color: $blue-120;

  &:hover,
  &:focus {
    border-color: transparent;
    background-color: transparent;
    color: $blue-130;
  }

  &:disabled {
    background-color: transparent;
    color: $black-60;
  }
}
</style>
