import axios from 'axios'
import { errorHandler } from '@/utils/errorHandler'

export const getMenu = async () => {
  const session_id = localStorage.getItem('sessionId')
  const request = await axios.get(
    '/proxy.php?className=menu&session_id=' + session_id
  )

  return await request.data
}

// export const getMainTitle = async () => {
//   try {
//     const request = await axios.get(
//       '/proxy.php?className=login&action=get_title_for_lkk'
//     )

//     return await request.data
//   } catch (error) {
//     throw new Error(error)
//   }
// }

export const getSteps = async (menuItemId) => {
  try {
    const request = await axios.get(
      `/proxy.php?className=questionnaire&action=get_lkk_pages&menu_item_id=${menuItemId}`
    )

    return await request.data
  } catch (error) {
    throw new Error(error)
  }
}

export const getPage = async ({ id, page } = {}) => {
  const session_id = localStorage.getItem('sessionId')

  const request = await axios.get('/proxy.php', {
    params: {
      className: 'menu',
      action: 'get_questionnaire',
      session_id,
      menu_item_id: id,
      page,
    },
  })

  return await request
}

export const saveQuestions = async (data) => {
  const session_id = localStorage.getItem('sessionId')
  let flatQuestions = []

  function flattenQuestions(arr = data.questions) {
    function addQuestion(q) {
      if (!q.hideAll || q.answer_id !== null) {
        flatQuestions.push({
          delete: q.delete || q.hideAll,
          answer_id: q.answer_id,
          id: q.etalon_id || q.id,
          value: Object.prototype.hasOwnProperty.call(q, 'hide_all')
            ? q.value && q.value != 'Нет'
              ? 'Да'
              : 'Нет'
            : q.value,
          answer_type: q.answer_type,
          additional: q.additional,
          sub_group_num: q.sub_group_num || 0,
          related_from_candidate_field: q.related_from_candidate_field,
        })
      }
    }

    arr.forEach((el) => {
      if (el.subquestions && el.subquestions.length) {
        flattenQuestions(el.subquestions)
      }

      if (Array.isArray(el)) {
        el.forEach((i) => {
          if (i.subquestions) {
            i.subquestions.forEach((s) => {
              addQuestion(s)
            })
          }
          addQuestion(i)
        })
      }

      if (el.answer_type !== 'file_answer' && el.question_type !== 'file') {
        addQuestion(el)
      }
    })
  }

  flattenQuestions()

  const params = new FormData()

  params.append('page_num', data.page_num)
  params.append('page_id', data.page_id)
  params.append('className', 'questionnaire')
  params.append('action', 'save')
  params.append('session_id', session_id)
  params.append('questions', JSON.stringify(flatQuestions))
  // if (data.files && Array.isArray(data.files)) {
  //   data.files.forEach((file) => params.append('questionnaire_files[]', file))
  // }
  if (data.final_page) params.append('final_page', true)

  try {
    const response = await fetch('/proxy.php', {
      method: 'POST',
      body: params,
    }).then((response) => response.json())

    return response
  } catch (e) {
    errorHandler(e)
  }
}

export const uploadFiles = async (data) => {
  const sessionId = localStorage.getItem('sessionId')
  const options = new FormData()

  if (data.files && Array.isArray(data.files)) {
    data.files.forEach((file) => options.append('questionnaire_files[]', file))
  }

  const request = await axios.post(
    `/proxy.php?className=questionnaire&action=save_files&session_id=${sessionId}&page_id=${data.page_id}&question_id=${data.question.id}`,
    options
  )

  return await request.data
}

export const removeFile = async (data) => {
  const session_id = localStorage.getItem('sessionId')

  const request = await axios.get('/proxy.php', {
    params: {
      className: 'questionnaire',
      action: 'delete_file',
      session_id,
      page_id: data.page_id,
      question_id: data.question_id,
      doc_id: data.doc_id,
      answer_id: data.answer_id,
    },
  })

  return await request.data
}
