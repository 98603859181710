import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main'

import { store } from '@/store'
import { errorHandler } from '../utils/errorHandler'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    beforeEnter: async (to, from, next) => {
      try {
        next()
      } catch (e) {
        console.error(e)
      }
    },
  },
  {
    path: 'new_page',
    name: 'changePage',
    beforeEnter: async (to, from, next) => {
      store.commit('loading', true)
      store.commit('page/setActiveMenu', to.params.page)

      try {
        await store.dispatch('page/loadSteps')
        await store.dispatch('page/loadQuestionaire', {
          page: 0,
        })
        next({
          name: 'questionnaire',
        })
      } catch (e) {
        errorHandler(e, next)
      }
    },
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/Questionnaire.vue'),
    beforeEnter: async (to, from, next) => {
      store.commit('loading', true)

      try {
        const menu = await store.dispatch('page/loadMenu')
        if (!menu) {
          localStorage.removeItem('sessionId')
          store.commit('loading', false)
          location.href = '/empty-questionnaire'
        }
        store.commit('page/setActiveMenu')
        await store.dispatch('page/loadSteps')
        await store.dispatch('page/loadQuestionaire', {
          page: 0,
        })
        next()
      } catch (e) {
        errorHandler(e, next)
      }
    },
    children: [
      {
        path: '/:questionnaire/:step',
        name: 'questionnairePage',
        component: () => import('../views/CustomQuestionnaire.vue'),
      },
    ],
  },
  {
    path: '/empty-questionnaire',
    name: 'emptyQuestionnairePage',
    component: () => import('../views/EmptyQuestionnaire.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
