import axios from 'axios'

export const generatePassword = async (login) => {
  const request = await axios.get('/proxy.php', {
    params: {
      className: 'login',
      action: 'send_password',
      login,
    },
  })

  return request
}

export const login = async ({ login, password }) => {
  const options = new FormData()
  options.append('className', 'login')
  options.append('login', login)
  options.append('pass', password)
  options.append('checkbox', 1)

  const request = await axios.post('/proxy.php', options)

  const sessionId = await request.data.data.session_id

  localStorage.setItem('sessionId', sessionId)

  return request
}
