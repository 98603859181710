import Vue from 'vue'
import Vuex from 'vuex'
import page from './modules/page'

// import { getMainTitle } from '@/controllers/page'
import { Message } from 'element-ui'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: { page },
  state: {
    loading: false,
    title: '',
    description: '',
  },
  mutations: {
    loading(state, val) {
      state.loading = val
    },
    showNotification(
      state,
      { message = '', type = 'success', duration = 5000 }
    ) {
      Message({
        offset: 100,
        showClose: true,
        duration,
        message,
        type,
      })
    },
  },
  // actions: {
  //   async loadMainTitle({ commit }) {
  //     const request = await getMainTitle()

  //     try {
  //       const { title_for_lkk } = await request.data

  //       commit('setMainTitle', title_for_lkk)
  //     } catch {
  //       const title =
  //         'Войдите в личный кабинет кандидата и заполните небольшую анкету, <br> чтобы стать частью команды!'

  //       commit('setMainTitle', title)
  //     }
  //   },
  // },
})
